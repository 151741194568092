var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar min-w-1400"},[_c('div',{staticClass:"secondBox max-w-1400"},[_vm._m(0),_c('ul',{staticClass:"nav-links"},[_c('li',{staticClass:"Li1",class:{
          'active-link':
            _vm.isActive('/') ||
            _vm.$route.path === '/about_us' ||
            _vm.$route.path === '/develop' ||
            _vm.$route.path === '/mission',
        }},[_c('router-link',{staticClass:"nav-link1",attrs:{"to":"/"}},[_c('span',{staticClass:"routeText1"},[_vm._v("Sobre nosotros")])])],1),_c('li',{staticClass:"Li2",class:{ 'active-link': _vm.$route.path === '/about' }},[_c('router-link',{staticClass:"nav-link2",attrs:{"to":"/about"}},[_c('span',{staticClass:"routeText2"},[_vm._v("Acerca de los préstamos")])])],1),_c('li',{staticClass:"Li3",class:{ 'active-link': _vm.$route.path === '/questions' }},[_c('router-link',{staticClass:"nav-link3",attrs:{"to":"/questions"}},[_c('span',{staticClass:"routeText3"},[_vm._v("Centro de problemas")])])],1),_c('li',{staticClass:"Li4",class:{ 'active-link': _vm.$route.path === '/protocol' }},[_c('router-link',{staticClass:"nav-link4",attrs:{"to":"/protocol"}},[_c('span',{staticClass:"routeText4"},[_vm._v("Acuerdo de privacidad")])])],1)])]),_c('div',{staticStyle:{"height":"130px"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('span',{staticClass:"title"},[_vm._v("Activos Axxis, S.A.P.I. de C.V., SOFOM, E.N.R")])])
}]

export { render, staticRenderFns }